<ng-container *ngIf="!isReady">
    <p>Cargando... {{ percentage }}%</p>
    <p>Actualizado 19-julio-2021 6:25pm</p>
</ng-container>

<div #unityEl id="gameContainer" 
      style="display: block; max-width: 75%; max-height: 75%; margin: auto;">
</div>

<div class="container-fluid">
  <div class="row">
    <div class="col-md-12">
      <p>{{name}} - {{username}}</p>
      <p>v1.5.1</p>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12">
      <button class="btn btn-login" routerLink="/perfil">
        Perfil
      </button>
      &nbsp;&nbsp;&nbsp;
      <button class="btn btn-logout" (click)="logout()">
        Salir
      </button>
    </div>
  </div>
</div>

<ng-template #contentImg let-modal>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">Examen físico: {{ examenFisico }}</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
        <img [src]="imgUrl" style="display: block; max-width: 76%; max-height: 76%; margin: auto">
    </div>
</ng-template>

<ng-template #contentAudio let-modal>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">Examen físico: {{ examenFisico }}</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
        <audio style="display: block; margin: auto;" [src]="audioUrl" controls autoplay muted>
            Su navegador no puede reproducir este audio. Por favor use Google Chrome para una mejor experiencia.
        </audio>
    </div>
</ng-template>

<ng-template #contentLabs let-modal>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">Ayudas diagnósticas ({{ labActual+1 }} de {{ labsTotales }})</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
        <h5>{{ titleLab }}</h5>

        <ng-container *ngIf="urlImgLab != null">
            <img [src]="urlImgLab" style="display: block; max-width: 75%; max-height: 75%; margin: auto">
        </ng-container>

        <ng-container *ngIf="valorLab != null && valorLab.trim() != ''">
            <p>Resultado:</p>
            <pre>{{ valorLab }}</pre>
        </ng-container>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline-dark" (click)="anteriorLab()">Anterior</button>
        <button type="button" class="btn btn-outline-dark" (click)="siguienteLab()">Siguiente</button>
    </div>
</ng-template>