<div class="container-fluid">
    <div class="row">
        <div class="col-md-3">

        </div>
        <div class="col-md-6 col-sm-12 text-center">
            <img class="logo" src="../../assets/img/uniandes-medicina.png" alt="Universidad de Los Andes - Facultad de Medicina"/>
        </div>
        <div class="col-md-3">

        </div>
    </div>
    <br />
    <br />
    <br />
    <div *ngIf="!isLoggedIn()" class="row">
        <div class="col-md-12 text-center">
            <h4>¡Bienvenidx al hospital simulado!</h4>
            <br />
            <button class="btn btn-login" (click)="login()">
                Iniciar sesión
            </button>
        </div>
    </div>
    <div *ngIf="isLoggedIn()" class="row">
        <div class="col-md-12 text-center">
            <h4>{{name}}</h4>
            <h5>{{username}}</h5>
            
            <button routerLink="/simulador" class="btn btn-login">
                Simulador
            </button>
            &nbsp;&nbsp;&nbsp;
            <button routerLink="/perfil" class="btn btn-login">
                Perfil
            </button>
            <br />
            <br />
            <hr />
            <p><strong>Atención:</strong> si el usuario de arriba no coincide con tu usuario, por favor cierra sesión e intenta de nuevo.</p>
            <br />
            <button class="btn btn-logout" (click)="logout()">
                Salir
            </button>
        </div>
    </div>
</div>